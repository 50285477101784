<template>
  <div
    class="card mb-4"
    v-if="
      this.$store.state.getMovie.status.tvShowLoading &&
      this.$store.state.getMovie.status.seasonShowLoading &&
      this.$store.state.getMovie.status.episodeShowLoading
    "
  >
    <div class="card-header bg-success">
      <h4 class="text-center text-white my-2">
        مشخصات قسمت {{ episode.episode_number }} فصل
        {{ episode.season_number }} برای {{ tv.name }}
      </h4>
    </div>
    <div class="card-body">
      <p class="text-center mb-0" dir="ltr">
        <strong>Title Episode: </strong>{{ episode.name }}
      </p>
      <p class="text-center mb-0" dir="ltr">
        <strong>overview: </strong>{{ episode.overview }}
      </p>
      <p class="text-center" dir="ltr">
        <strong>Vote Average: </strong>{{ episode.vote_average }}/10 for
        {{ episode.vote_count }} Vote
      </p>
      <h4
        class="text-center p-2 m-0 bg-color-primary-2 text-white"
        v-if="episodeCrew.length > 0"
      >
        مشخصات عوامل فیلم
      </h4>
      <div class="table-responsive mb-3" v-if="episodeCrew.length > 0">
        <table
          class="table table-sm table-striped table-hover align-middle text-center"
        >
          <thead class="table-success">
            <tr>
              <th scope="col">#</th>
              <th scope="col">تصویر</th>
              <th scope="col">جنسیت</th>
              <th scope="col">نام و نام خانوادگی</th>
              <th scope="col">نقش</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(itemCrew, index) in episodeCrew" :key="itemCrew.id">
              <th scope="row">{{ index + 1 }}</th>
              <td>
                <img
                  style="width: 80px"
                  v-if="itemCrew.profile_path"
                  class="img-fluid rounded-1"
                  :src="imageSizeMovie('w185') + itemCrew.profile_path"
                  :alt="itemCrew.original_name"
                  :title="itemCrew.original_name"
                />
                <img
                  style="width: 80px"
                  v-else
                  class="img-fluid rounded-1"
                  src="../../assets/no_image.jpg"
                  :alt="itemCrew.original_name"
                  :title="itemCrew.original_name"
                />
              </td>
              <td>{{ this.setGender(itemCrew.gender) }}</td>
              <td>{{ itemCrew.original_name }}</td>
              <td>{{ itemCrew.job }}</td>
            </tr>
          </tbody>
        </table>
      </div>
      <h4
        class="text-center p-2 m-0 bg-color-primary-2 text-white"
        v-if="episodeGuestStars.length > 0"
      >
        مشخصات بازیگران این قسمت
      </h4>
      <div class="table-responsive" v-if="episodeGuestStars.length > 0">
        <table
          class="table table-sm table-striped table-hover align-middle text-center"
        >
          <thead class="table-success">
            <tr>
              <th scope="col">#</th>
              <th scope="col">تصویر</th>
              <th scope="col">نام و نام خانوادگی</th>
              <th scope="col">جنسیت</th>
              <th scope="col">در نقش</th>
              <th scope="col">پروفایل</th>
            </tr>
          </thead>
          <tbody>
            <tr
              v-for="(itemGuestStars, index) in episodeGuestStars"
              :key="itemGuestStars.id"
            >
              <th scope="row">{{ index + 1 }}</th>
              <td>
                <img
                  style="width: 80px"
                  v-if="itemGuestStars.profile_path"
                  class="img-fluid rounded-1"
                  :src="imageSizeMovie('w185') + itemGuestStars.profile_path"
                  :alt="itemGuestStars.original_name"
                  :title="itemGuestStars.original_name"
                />
                <img
                  style="width: 80px"
                  v-else
                  class="img-fluid rounded-1"
                  src="../../assets/no_image.jpg"
                  :alt="itemGuestStars.original_name"
                  :title="itemGuestStars.original_name"
                />
              </td>
              <td>{{ this.setGender(itemGuestStars.gender) }}</td>
              <td>{{ itemGuestStars.original_name }}</td>
              <td>{{ itemGuestStars.character }}</td>
              <td>
                <router-link
                  class="btn btn-success btn-sm text-white text-end"
                  :to="'/person/' + itemGuestStars.id"
                  >مشاهده پروفایل</router-link
                >
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
  <div class="card" v-else>
    <spinner />
  </div>
</template>

<script>
import Spinner from "@/components/template/spinner";
export default {
  name: "EpisodeTvDetail",
  components: { Spinner },
  props: {
    tv: {
      type: Object,
      required: true,
    },
    season: {
      type: Object,
      required: true,
    },
    episode: {
      type: Object,
      required: true,
    },
    episodeCrew: {
      type: Object,
      required: true,
    },
    episodeGuestStars: {
      type: Object,
      required: true,
    },
  },
  methods: {
    setGender(value) {
      switch (value) {
        case 0:
          return "نامشخص";
        case 1:
          return "خانم";
        case 2:
          return "آقا";
      }
    },
  },
};
</script>

<style scoped></style>
